import React from 'react';
import Alen from '../../images/creator/creador_2.jpg';
import Pablo from '../../images/creator/creador_1.jpg';
import Lord from '../../images/creator/creador_3.jpg';
import { ServicesContainer, ServicesH1, ServicesWrapper, ServicesCard, ServicesIcon, ServicesH2, ServicesP } from './ServicesElements';

const Team = () => {
  return (
    <ServicesContainer id='team'>
      <ServicesH1>WHO THE F*CK</ServicesH1>
      <ServicesP> ARE THE TEAM</ServicesP> 
      <ServicesWrapper>
     
        <ServicesCard>
          <ServicesIcon src={Alen} />
          <ServicesH2>ALEN VALDIVIA</ServicesH2>
          </ServicesCard>
     
        <ServicesCard>
          <ServicesIcon src={Pablo} />
          <ServicesH2>PABLO BENLLIURE</ServicesH2>
        </ServicesCard>
      
        <ServicesCard>
          <ServicesIcon src={Lord} />
          <ServicesH2>LORD K2</ServicesH2>
        </ServicesCard>
        
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default Team;
