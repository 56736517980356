import styled from 'styled-components';
import isMobile from "ismobilejs";

const mobile = isMobile().any ? "margin-top: 10em" : "margin-top: 0;"

export const ServicesContainer = styled.div`
  height: 800px;
  display: flex;
  flex-direction: column;
  ${mobile};
  background-position: center;
  width: 100%;
 

  @media screen and (max-width: 768px) {
    height: 10em;
    margin-top: 20em;
  }

  @media screen and (max-width: 375px) {
    height: 410px;
  }

  @media screen and (max-width: 480px) {
    align-items: center;
    margin-top: 15em;
    height: 0px;
    padding-bottom: 70em;
  }
`;

export const ServicesWrapper = styled.div`
  position: relative;
  max-width: 100%;
  align-items: left;
  justify-items: left;
  margin-left: 15rem;
  padding-right: 15em;

  @media screen and (max-width: 1000px) {
    
  }

  @media screen and (max-width: 375px) {
    padding-left: 3rem;
    padding-right: 1rem;
  }


  @media screen and (max-width: 480px) {
    margin-left: 0px;
    padding-right: 1em;
    padding-left: 3em;
  }
`;
export const ServicesLinea= styled.div`
position: relative;
border-bottom: 2px solid rgb(146, 0, 234); 
width: 4rem;
top:-1.0rem;

`;

export const ServicesIcon = styled.img`
  height: 160px;
  width: 160px;
  margin-bottom: 10px;
`;

export const ServicesH1 = styled.h1`
margin-top:5%;

font-family: 'Teko', sans-serif;
font-size: 4rem!important;
font-weight: 900;
color:rgb(22, 238, 189);
  @media screen and (max-width: 480px) {
    font-size: 2rem;
  
  }
`;

export const ServicesH2 = styled.h2`

  font-size: 1rem;
  margin-bottom: 10px;
  color:rgb(146, 0, 234);
`;
export const ServicesH3 = styled.h2`
  font-size: 0.6rem;
  font-family: roboto;
  margin-bottom: 10px;
  color:rgb(247, 247, 247)!important;
`;

export const ServicesP = styled.p`
index: 1;
position:absolute;
  margin-left: -13px;
  margin-top: -8px;
  font-size: 1.7rem;
  text-align:left;
  color:white;
`;
