import React from "react";
import estrella from "../../images/LINEAPUNTO.svg";
import base from "../../images/graficos/BASE.png";
import man from "../../images/graficos/ICONO.png";
import cohete2 from "../../images/graficos/COHETE.png";
import {
  Cohete,
  Base,
  Man,
  ServicesCoete,
  ServicesEstrella,
  ServicesEstrella2,
  ServicesEstrella3,
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesP,
  HideOnMobile,
} from "./ServicesElements";
const Launch = () => {
  return (
    <>
      <ServicesContainer id="launch">
        <ServicesWrapper>
          <ServicesH1>LAUNCH</ServicesH1>
          <ServicesP>
            {" "}
            Launch of our social networks <br />+ Main Web Page + Smart
            contracts{" "}
          </ServicesP>
          <ServicesH1>EXHIBITION</ServicesH1>
          <ServicesEstrella src={estrella} />
          <ServicesEstrella2 src={estrella} />
          <ServicesEstrella3 src={estrella} />
        </ServicesWrapper>

        <ServicesP>
          Exhibition and marketing of Pablo Benlliure's first
          <br />
          digitized collection: Through the Gods.
        </ServicesP>
        <ServicesP>
          The first collection of Transcendence will consist <br />
          of 990 works. The first 33 will be the mother
          <br />
          pieces that lead the family to make way for the 29
          <br />
          daughter works for each mother piece marketed.
        </ServicesP>
        <ServicesH1>BENEFITS</ServicesH1>

        <ServicesP>
          The beneficiaries who manage to buy some of the
          <br />
          first 33 mother works will obtain the following <br />
          benefits:{" "}
        </ServicesP>
        <ServicesP>
          {" "}
          › Position in Whitelist to acquire more works of said collection.
          <br />
          › You will get 5% of the total value sold in the 29 daughter pieces
          <br />
          belonging to the family of your obtained work.
          <br />
          › Access to rooms and exclusive talks with the artist about his
          <br />
          works and future projects within the ecosystem.
          <br />
          › A masterclass with the artist and the founders of the project.
          <br />
          › Position within the core team with voting rights for future
          <br />
          project decisions (DAO)
          <br />
          › VIP and priority lifetime access to the TRANSCENDENCE art
          <br />
          platform where valued artists from all over the world will exhibit
          <br />
          digital art.
          <br />
        </ServicesP>
        <HideOnMobile>
        <ServicesCoete></ServicesCoete>
        <Cohete src={cohete2} />
        <Man src={man} />
        <Base src={base} />
        </HideOnMobile>
      </ServicesContainer>
    </>
  );
};
export default Launch;
