import React from "react";
import {
  ServicesWH1,
  ServicesContainer,
  ServicesRoadmap,
  ServicesH1,
  ServicesP,
} from "./ServicesElements";
import Additional from "../Additional";
import Launch from "../Launch";

const Roadmap = () => {
  return (
    <>
      <ServicesContainer id="roadmap">
        <ServicesRoadmap>
          <ServicesH1>ROADMAP</ServicesH1>
          <ServicesWH1>PRIME TRASCENDENCE</ServicesWH1>
          <ServicesP>
            Art is one of the ways in which our search for transcendence is
            expressed. The beyond harbors a mystery, art pursues that mystery
            and traces the very path of access to a new plane through creation.
          </ServicesP>
          <ServicesP>
            This is a platform open to creation in which we find ourselves
            through art where many paths come together to form a community that
            pursues a common goal: Transcendence.
          </ServicesP>
          <ServicesP>
            Now, at Transcendence we don't just create experiences, we also
            create value.
          </ServicesP>
          <ServicesP>
            With your investment, in addition to becoming a patron of one of the
            most renowned artists in Latin America, and obtaining one of his
            first digital works, you will be able to access the benefits named
            in the the Roadmap.
          </ServicesP>

          <br />
          <div>
            <a className="A-boton" href="#launch">
              SCROLL DOWN
            </a>
          </div>
        </ServicesRoadmap>
        <Launch />
        <Additional />
      </ServicesContainer>
    </>
  );
};

export default Roadmap;
