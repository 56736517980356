import React, { useState, useEffect } from 'react';
import { FaBars } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import { MenuIcon, Nav, NavbarContainer, NavItem, NavLink, NavMenu, } from './NavbarElements';

const Navbar = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeNav);
  }, []);

  return (
    <>
      <IconContext.Provider value={{ color: '#fff' }}>
        <Nav scrollNav={scrollNav}>
          <NavbarContainer>
            <MenuIcon onClick={toggle}>
              <FaBars />
            </MenuIcon>
            <NavMenu>
              {/* <NavItem>
                <NavLink to='home' smooth={true} duration={500} spy={true} exact='true' offset={-80} >HOME</NavLink>
              </NavItem> */}
              <NavItem>
                <NavLink to='welcome' smooth={true} duration={500} spy={true} exact='true' offset={-80}>WELCOME</NavLink>
              </NavItem>
              <NavItem>
                <NavLink to='collection' smooth={true} duration={500} spy={true} exact='true' offset={-80}>COLLECTION</NavLink>
              </NavItem>
              <NavItem>
                <NavLink to='artist' smooth={true} duration={500} spy={true} exact='true' offset={-80}>ARTIST</NavLink>
              </NavItem>
              <NavItem>
                <NavLink to='roadmap' smooth={true} duration={500} spy={true} exact='true' offset={-80}>ROADMAP</NavLink>
              </NavItem>
              <NavItem>
                <NavLink to='team' smooth={true} duration={500} spy={true} exact='true' offset={-80}>TEAM</NavLink>
              </NavItem>
              <NavItem>
                <NavLink to='partners' smooth={true} duration={500} spy={true} exact='true' offset={-80}>PARTNERS</NavLink>
              </NavItem>
              
              <NavItem>
                <NavLink to='faqs' smooth={true} duration={500} spy={true} exact='true' offset={-80}>FAQS</NavLink>
              </NavItem>
              
            </NavMenu>
           
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
};

export default Navbar;
