import styled from 'styled-components';
import { Link } from 'react-scroll';


export const ServicesContainer = styled.div`
  height: 600px;
  display: flex;
  flex-direction: column;
  padding-top: 12em;
  padding-bottom: 35em;
 
  background-position: center;
  align-items: left;
 
 margin-left: 8rem;
 @media screen and (max-width: 768px) {
  margin-top: 25em;
  position: relative;
  align-items: center;
  margin-left: 0em;
} 
@media screen and (max-width: 480px) {
  margin-top: 1em;
  padding-top: 4em;
  margin-bottom: 55em;
  padding-bottom: 40em;
  position: relative;
  margin-left: 0;
}
@media screen and (max-width: 375px) {
  height: 15em;
  margin-top: 0em;
  text-align: justify;
  padding-top: 0em;
  padding-bottom: 5em;
  position: relative;
  align-items: center;
  margin-left: 7em;
  }
`;

export const ServicesWrapper = styled.div`
  max-width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-gap: 16px;
  padding-left: 19rem;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
    align-text: center;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;

  }
  @media screen and (max-width: 375px) {
    max-width: 0em;
    }


`;
export const ServicesWrapper2 = styled.div`
  max-width: 400px;
 
  align-items: center;
  text-align: center;
 

  @media screen and (max-width: 1000px) {
   
  }

  @media screen and (max-width: 768px) {
   
  
  }
  @media screen and (max-width: 375px) {
    max-width: 300px;
    }

`;

export const ServicesWrapper3 = styled.div`
display: flex;
align-items: center;
justify-content: space-evenly;
`

export const ServicesArtst= styled.div`
  margin-top: 0rem;
  max-width:400px;
  align-items: center;
  text-align: center;
  position: absolute;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.10);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    position: relative;
    align-items: center;
  }
  @media screen and (max-width: 375px) {
    max-width: 350px;
    &:hover {
      transform: scale(1.01);
      transition: all 0.2s ease-in-out;
      cursor: pointer;
    }
    }
`;

export const ServicesIcon = styled.img`
  height:auto;
  width: 60px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 1rem;
 
`;

export const ServicesH1 = styled.h1`
margin-top:-7%;
font-family: 'Teko', sans-serif;
font-size: 5rem;
font-weight: 900;

color:rgb(22, 238, 189);
  @media screen and (max-width: 480px) {
    font-size: 3rem;
    margin-top:242%;
  }
  @media screen and (max-width: 375px) {
    margin-top: 10em;
    }
`;

export const ServicesH2 = styled.h2`
  font-size: 1rem;
  margin-bottom: 10px;
`;
export const ServicesWH1 = styled.h1`
font-family: 'Teko', sans-serif;
font-size: 1.9rem!important;
font-weight: 900;
color:rgb(146, 0, 234);
  @media screen and (max-width: 375px) {
    font-size: 1rem;
  }
  @media screen and (max-width: 480px) {
    font-size: 1rem;
    margin-left: 1rem;
  }
`;
export const ServicesP = styled.p`
padding-top: 2rem;
font-family:'roboto', sans-serif;
  font-size: 1rem;
  color: #fff !important;
  text-align: center;
  @media screen and (max-width: 375px) {
    font-size: 0.8rem;
  }
  @media screen and (max-width: 414px) {
    font-size: 0.8rem;
    margin-left: 0rem;
  }
`;
export const ButtonDiscord = styled(Link)`
border: 1px solid #16EEBD;
color: #16EEBD !important;
border-radius: 9px;
width: 18rem;
font-size: 1.2rem;
margin-top: 2rem;
padding-left: 1.5rem;
padding-right: 1.5rem;
`;
