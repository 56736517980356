import styled from 'styled-components';
import { Link as LinkR } from 'react-router-dom';
import { Link as LinkS } from 'react-scroll';

export const Nav = styled.nav`
  background: ${({ scrollNav }) => (scrollNav ? 'transparent' : 'transparent')};;
  margin-top: 0px;
  display: inline;
  right: 0px;
  position:relative;
  font-size: 1rem;
  position: fixed;
  top: 34%;
  z-index: 10;
  @media screen and (max-width: 960px) {
    transition: 0.8s all ease;
  }
`;

export const NavbarContainer = styled.div`
  height: 20px;
  max-width: 100%;
`;

export const NavLogo = styled(LinkR)`
  color: #fff;
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1.5rem;
 
  align-items: center;
  margin-left: 24px;
  font-weight: bold;
  text-decoration: none;
`;

export const MenuIcon = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
  
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #fff;
  }
`;

export const NavMenu = styled.ul`
position: relative;
display: inline;
right: 0px;
display: inline;
  list-style: none;
  text-align: left;
  /* margin-right: -22px; */

  @media screen and (max-width: 768px) {
    right: -1000%;
  }
`;

export const NavItem = styled.li`
  height: 35px;  
`;

export const NavLink = styled(LinkS)`
  color: #fff;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 30%;
  
  cursor: pointer;

  &.active {
    border-bottom: 3px solid #01bf71;
  }
`;

export const NavBtn = styled.nav`

  align-items: center;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavBtnLink = styled(LinkR)`
  border-radius: 50px;
  background: #01bf71;
  white-space: nowrap;
  padding: 10px 22px;
  color: #010606;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
  }
`;
