import React from 'react';
import Nft1 from '../../images/nft/nft-a.png';
import Nft2 from '../../images/nft/nft-b.png';
import { ServicesContainer, ServicesH1, ServicesWrapper, ServicesIcon, ServicesP } from './ServicesElements';

const Additional = () => {
  return (
    <ServicesContainer >

      <ServicesH1>ADDTIONAL</ServicesH1>
        <ServicesWrapper>
            <ServicesIcon src={Nft1} />
            
      <ServicesP>In addition to the original NFT, the owners of the mother
works will receive a delivery of the work obtained in a 3D
format that can be used for different formats and digital
integrations, giving more value to the original piece in a

more attractive format for the metaverse.</ServicesP><ServicesIcon src={Nft2} />
        </ServicesWrapper>
     
    </ServicesContainer>
  );
};

export default Additional;
