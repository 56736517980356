
import React, { useState } from "react";
import App from "../App.css";
import Navbar from "../components/Navbar";

import Sidebar from "../components/Sidebar";

import NavbarSocial from "../components/NavbarSocial";
const SigninPage = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
  <>
   <div className="Container-1">
   <div className="faqs" >
      What is Transcendence?
It is a digital space adapted on web 3.0 for emerging and expanding artists where dreamers, enthusiasts, and investors believe in the future of cryptocurrencies and blockchain technology.
      </div>
      <a href="/#faqs">Go back</a>
   </div>
      
      <NavbarSocial />
      

  </>
  );
};

export default SigninPage;
