import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './pages';
import Faqs1 from './pages/faqs1';
import Faqs2 from './pages/faqs2';
import Faqs3 from './pages/faqs3';
import Faqs4 from './pages/faqs4';
import Mint from './pages/mint';
import Needhelp from './pages/needhelp';
import Coins from './components/Coins';
import Areyouanartist from './pages/areyouanartist';
function App() {
  return (
    <Router>
      <Switch>
        <Route path="/"  exact>
        <Home />
        </Route>
        <Route path="/WHAT-IS-TRANSCENDENCE" exact>
        <Faqs1 />
        </Route>
        <Route path="/DOES-THE-FIRST-TRANSCENDENCE-COLLECTION-HAVE-ANY-USE" exact>
        <Faqs2 />
        </Route>
        <Route path="/HOW-MANY-NFTS-ARE-THERE-IN-THE-THROUGH-THE-GOODS-COLLECTION" exact>
        <Faqs3 />
        </Route>
        <Route path="/WHY-SHOULD-I-INVEST-IN-TRANSCENDENCE-NFTS" exact>
        <Faqs4 />
        </Route>
        <Route path="/Need-help" exact>
        <Needhelp />
        </Route>
        <Route path="/Search-a-coin" exact>
        <Coins />
        </Route>
        <Route path="/Mint" exact>
        <Mint />
        </Route>
        <Route path="/Are-you-an-Artist" exact>
        <Areyouanartist />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
