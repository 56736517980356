import React from "react";
import './artist.css';
import {
  ServicesArtst,
  ServicesContainer,
  ServicesWH1,
  ServicesH1,
  ServicesWrapper3,
  ServicesWrapper2,
  ServicesWrapper,
  ServicesIcon,
  ServicesP,
} from "./ServicesElements";
import Slidershow from "../Slidershow";
import Nike from "../../images/marcas/NIKE_1.png";
import Vans from "../../images/marcas/VANS_1.png";

const Artist = () => {
  return (
    <>
      <ServicesContainer id="artist">
        <ServicesWrapper2>
          <ServicesH1>THE ARTIST</ServicesH1>
          <ServicesArtst>
            <ServicesWH1> WHO THE F*CK IS PABLO BENLLIURE?</ServicesWH1>
            <ServicesP>
              Pablo Benlliure, part of the core team, is one of the best URBAN
              artists we have in Latin america, a native from Mexico City, and
              the Creator of Through The Gods: We will start the platform with
              his own NFT collection.
            </ServicesP>
            <ServicesP>
              A collection focused on the forms in which the gods appear in our
              lives that show us different realities, pushing us to evolve and
              transcend. Among his most recognized works are his collaborations
              with:
            </ServicesP>
            <br />
            <ServicesWrapper3>
            <ServicesIcon src={Nike} />

            <ServicesIcon src={Vans} />
            </ServicesWrapper3>
            <br />
            <a className="A-boton"  href="https://discord.gg/QpaZbcJ4NQ"
                target="_blank"
                rel="noopener noreferrer"> CLIK HERE TO JOIN THE DISCORD</a>
          </ServicesArtst>
        </ServicesWrapper2>
        <ServicesWrapper>
          <Slidershow/>
        </ServicesWrapper>
      </ServicesContainer>
    </>
  );
};

export default Artist;
