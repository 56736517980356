import styled from 'styled-components';

export const ServicesContainer = styled.div`
  height: 30rem;
  display: flex;
  flex-direction: column;

  background-position: center;
  align-items: center;
 

  @media screen and (max-width: 768px) {
    height: 1100px;
  }


  @media screen and (max-width: 480px) {

  }

  @media screen and (max-width: 375px) {
    height: 300px;
   
  }
`;

export const ServicesWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 16px;
 
  @media screen and (max-width: 1000px) {

  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
`;


export const ServicesIcon = styled.img`
  margin: auto;
  height: 160px;
  width: 160px;
  margin-top:-6rem;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.30);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    margin-top: 1em;
  }

  @media screen and (max-width: 480px) {
    margin-top: 1em;
  }
  @media screen and (max-width: 375px) {
    margin-top: 1rem;
  }
`;

export const ServicesH1 = styled.h1`
margin-top:16%;
font-family: 'Teko', sans-serif;
font-size: 4rem;
font-weight: 900;
color:rgb(22, 238, 189);
  @media screen and (max-width: 375px) {
    margin-top:20%;
    font-size: 3rem;
  }
`;

export const ServicesP = styled.p`
  font-size: 1rem;
  text-align: center;
  color:#fff;
  
`;
