import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import item1 from "../../images/ROPA/item_1.jpg";
import item2 from "../../images/ROPA/item_2.jpg";
import item3 from "../../images/ROPA/item_3.jpg";
import item4 from "../../images/ROPA/item_4.jpg";
import item5 from "../../images/ROPA/item_5.jpg";
import item6 from "../../images/ROPA/item_6.jpg";
import item7 from "../../images/ROPA/item_7.jpg";
import item8 from "../../images/ROPA/item_8.jpg";
import item9 from "../../images/ROPA/item_9.jpg";
import item10 from "../../images/ROPA/item_10.jpg";
import item11 from "../../images/ROPA/item_11.jpg";

import {
  Slide,
  ContenedorSlideshow,
  ServicesContainer,
  ServicesIcon,
} from "./ServicesElements";



const Slidershow = () => {

  const slideshow = useRef(null);
  const intervaloSlides = useRef(null);

  const siguiente = ()=>{

    if(slideshow.current.children.length > 0){
      const primerElemento = slideshow.current.children[0];//ok

      slideshow.current.style.transition = `2002ms ease-out all`; //ok

       const tamañoSlider = slideshow.current.children[0].offsetWidth; //ok

       slideshow.current.style.transform = `translateX(-${tamañoSlider}px)`;//ok
       
        const dale = () => {  
         slideshow.current.style.transition = 'none'; 
         slideshow.current.style.transform = `translateX(0)`;
         slideshow.current.appendChild(primerElemento);
       }
          slideshow.current.addEventListener('transitionend', dale);
     }
  }
 
  useEffect(()=>{
    intervaloSlides.current  = setInterval(()=>{
      siguiente();
     
    },1000);
    slideshow.current.addEventListener('mouseenter',() => {
      clearInterval(intervaloSlides.current);
    });
    slideshow.current.addEventListener('mouseleave',() => {
      
      intervaloSlides.current  = setInterval(()=>{
        siguiente();
      },1000);

    });

  },[]);
  



  return (
  
    <ServicesContainer>

      
      <ContenedorSlideshow ref={slideshow}>
      
        <Slide>
          <ServicesIcon src={item1} alt="" />
        </Slide>
        <Slide>
          <ServicesIcon src={item2} alt="" />
        </Slide>
        <Slide>
          <ServicesIcon src={item4} alt="" />
        </Slide>
        <Slide>
          <ServicesIcon src={item3} alt="" />
        </Slide>
        <Slide>
          <ServicesIcon src={item5} alt="" />
        </Slide>
        <Slide>
          <ServicesIcon src={item6} alt="" />
        </Slide>
        <Slide>
          <ServicesIcon src={item7} alt="" />
        </Slide>
        <Slide>
          <ServicesIcon src={item8} alt="" />
        </Slide> 
        <Slide>
          <ServicesIcon src={item9} alt="" />
        </Slide>
        <Slide>
          <ServicesIcon src={item10} alt="" />
        </Slide>
        <Slide>
          <ServicesIcon src={item11} alt="" />
        </Slide>
      </ContenedorSlideshow>
     
      </ServicesContainer>

  );
};


export default Slidershow;
