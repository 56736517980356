import styled from 'styled-components';
import { Link } from 'react-scroll';

export const Button2 = styled(Link)`
  border-radius: 15px;
  border: 1px solid rgb(22, 238, 189);
  background: ${({ primary }) => (primary ? '#01BF71' : 'transparent')};
  white-space: nowrap;
  padding: ${({ big }) => (big ? '14px 48px' : '6px 30px')};
  color: ${({ dark }) => (dark ? '#010606' : 'rgb(22, 238, 189)')};
  font-size: ${({ fontBig }) => (fontBig ? '27px' : '13px')};
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({ primary }) => (primary ? 'rgb(22, 238, 189)' : 'transparent')};
  }
`;


