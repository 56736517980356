import React from "react";
import './style.css'
import { ServicesContainer, } from "./ServicesElements";
import roadmap from "../../images/ROADMAP.png";
const RoadMapGrafico = () => {
  return (
    <>
      <ServicesContainer>
      <img src={roadmap} alt="Roadmap" />
      </ServicesContainer>
    </>
  );
};

export default RoadMapGrafico;
