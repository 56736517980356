import styled from 'styled-components';

export const ServicesContainer = styled.div`

  height: 800px;
  display: flex;
  margin-top: 5em;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 768px) {
    height: 1100px;
  }

  @media screen and (max-width: 480px) {
    height: 477px;
    margin-top: 40em;
  }
  @media screen and (max-width: 375px) {
    height: 477px;
    margin-top: 40em;
    }
`;
export const ServicesWH1 = styled.h1`
margin-top:15%;
font-family: 'Roboto', sans-serif;
font-size: 4rem!important;
font-weight: 900;
color:rgb(22, 238, 189);
  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
  @media screen and (max-width: 375px) {
    font-size: 3rem !important;
  margin-top: -156%;}
`;
export const ServicesWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 16px;
  padding: 0 50px;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;

  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 20px;

  }
  @media screen and (max-width: 375px) {
    margin-top: 5em;
  }
`;

export const ServicesCard = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  max-height: 340px;
  padding: 30px;
  margin: 2rem;
  border: 2px solid rgb(65, 53, 248);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.15);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
  @media screen and (max-width: 375px) {
    &:hover {
      transform: scale(1.0);
      transition: all 0.2s ease-in-out;
      cursor: pointer;
    }
  }
`;

export const ServicesIcon = styled.img`
  height: 160px;
  width: 160px;
  margin-bottom: 10px;
`;

export const ServicesH1 = styled.h1`
margin-top:5%;
font-family: 'Teko', sans-serif;
font-size: 4rem!important;
font-weight: 900;
color:rgb(22, 238, 189);
  @media screen and (max-width: 375px) {
    font-size: 3rem;
  }
`;

export const ServicesH2 = styled.h2`
  font-size: 1rem;
  margin-bottom: 10px;
`;

export const ServicesP = styled.p`
  font-size: 1rem;
  text-align: center;
`;
export const ServicesColleH2 = styled.h2`
color: rgb(65, 53, 248);
font-family: 'Caveat', cursive;
font-size: 1.2rem;
font-weight: 900;
margin-top: 1rem;
@media screen and (max-width: 480px) {
  margin-top: 3rem;
}
`;
export const ServicesColleH2B = styled.h2`
color: rgb(101, 59, 191);
font-family: 'Roboto', sans-serif;
font-size: 1.2rem;
font-weight: 900;
margin-bottom: -11rem;

@media screen and (max-width: 1000px) {
  display: none;

  }

  @media screen and (max-width: 768px) {
    display: none;

  }
  @media screen and (max-width: 375px) {
   display: none;
  }

`;
