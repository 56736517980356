import styled from 'styled-components';

export const ServicesContainer = styled.div`
  margin-bottom:0px;
  height: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  @media screen and (max-width: 1000px) {
  }

  @media screen and (max-width: 768px) {

  }
  @media screen and (max-width: 375px) {
    
  }
`;
export const WelcomeDivLogo = styled.div`
    margin-top: 7rem;
    height: 800px;
    text-align: center;
    content-align: center
    @media screen and (max-width: 375px) {
      margin-top: |1rem;
      height: 800px;
    }
    padding-bottom: 2em;
`;
export const WelcomeLogo = styled.img`
width: 80%;
height: auto;
`;
export const WelcomeP = styled.p`
color: rgb(22, 238, 189)!important;
font-family: 'Roboto', sans-serif;
font-size: 0.75rem;
font-weight: 900;
margin-top: 3rem;
`;
export const WelcomeDivButt = styled.div`
    margin-bottom: 15rem;
    width: 15rem;
    height: 19px;
    text-align: center;
`;
export const ServicesWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 16px;
  padding: 0 50px;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
  @media screen and (max-width: 375px) {
    max-width: 375px;
    grid-template-columns: 1fr ;
    padding: 0 0px;
  }
`;




export const WelcomeContainerDos = styled.div`
margin-top: -17.2rem;
  height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  @media screen and (max-width: 768px) {
    height: 1100px;
  }

  @media screen and (max-width: 480px) {
    height: 2px;
  }
  @media screen and (max-width: 375px) {
    height: 600px;
  }
`;
export const WelcomeSeparador = styled.div`
  margin-top: 9rem;
  
`;
export const WelcomeContainerDosB = styled.div`
  margin-top: 0rem;
  padding-left: 7rem;
  padding-right: 7rem;
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  @media screen and (max-width: 768px) {
   
  }

  @media screen and (max-width: 480px) {
    padding-left: 0.1rem!important;
    padding-right: 0.1rem!important;
  }
`;

export const ServicesWIcon = styled.img`
  height: 200px;
  width: 160px;
  
  border-radius: 10px;
`;

export const ServicesWH1 = styled.h1`
margin-top:22%;
font-family: 'Teko', sans-serif;
font-size: 3.5rem!important;
font-weight: 900;
color:rgb(22, 238, 189);
  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
`;

export const ServicesWH2 = styled.h2`
color: rgb(101, 59, 191);
font-family: 'Roboto', sans-serif!;
font-size: 1.5rem;
font-weight: 900;
margin-top: 1rem;
margin-bottom: 1rem;
@media screen and (max-width: 480px) {
  margin-top: 3rem;
}
`;

export const ServicesWP = styled.p`
font-family: 'Roboto', sans-serif;
margin-bottom: 1rem;
color: #fff !important;
  font-size: 1rem;
  text-align: center;
 
`;
export const WelcomeRoadmap = styled.img`
margin-top: -252px;
width: 1377px;
image-responsive: true;
  @media screen and (max-width: 480px) {
    height:12rem;
  }
  @media screen and (max-width: 375px) {
    height:auto;
    width:100%;
  }
  @media screen and (max-width: 600px) {
    height:auto;
    width:100%;
  }
  @media screen and (max-width:  800px) {
    height:auto;
    width:100%;
  }
`;



