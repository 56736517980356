import styled from 'styled-components';
import { Link } from 'react-scroll';
export const ServicesContainer = styled.div`
  height: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
 
  @media screen and (max-width: 768px) {
    height: 1100px;
  }

  @media screen and (max-width: 480px) {
    margin-left: 2em;
    margin-right: 2em;
  }

  @media screen and (max-width: 375px) {
    height: 1300px;
    margin-top: 0em;
  }
`;
export const ServicesRoadmap= styled.div`
  max-width: 600px;
 
  align-items: center;
  text-align: center;
 

  @media screen and (max-width: 1000px) {
   
  }

  @media screen and (max-width: 375px) {
    max-width: 300px;
  
  }
`;
export const ServicesWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 16px;
  padding: 0 50px;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }

  
  @media screen and (max-width: 414px) {
    grid-template-columns: 1fr;
    padding: 0 10px;
  }
`;

export const ServicesCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  max-height: 340px;
  margin: 3.5rem;
  border: 1px solid rgb(247, 237, 54);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.12);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
`;

export const ServicesIcon = styled.img`
  height: 200px;
  width: 160px;
  border-radius: 10px;
`;

export const ServicesH1 = styled.h1`

font-family: 'Teko', sans-serif;
font-size: 5rem;
font-weight: 900;
color:rgb(22, 238, 189);
  @media screen and (max-width: 375px) {
    font-size: 3rem;
    margin-top:0%;
  }
  @media screen and (max-width: 414px) {
    font-size: 3rem;

  }
`;

export const ServicesWH1 = styled.h2`
color: rgb(146, 0, 234);
  font-size: 1.5rem;
  margin-bottom: 10px;
`;

export const ServicesP = styled.p`
  font-size: 1rem;
  text-align: center;
  color:#fff;
  font-family: 'Roboto', sans-serif;
  margin-top: 2rem;
  @media screen and (max-width: 375px) {
    font-size: 0.8rem;
 
  }
`;
export const ButtonScroll = styled(Link)`
border: 1px solid #16EEBD;
color: #16EEBD !important;
border-radius: 9px;
width: 18rem;
font-size: 1.2rem;
margin-top: 2rem;
padding-left: 1.5rem;
padding-right: 1.5rem;
`;
