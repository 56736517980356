
import React, { useState } from "react";
import App from "../App.css";
import Navbar from "../components/Navbar";

import Sidebar from "../components/Sidebar";

import NavbarSocial from "../components/NavbarSocial";
const SigninPage = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
  <>
   <div className="Container-1">
   
   <div className="faqs" >How many NFTs are there in the Through-the-goods collection?
Transcendence will release Pablo Benlliure's first digital collection of 990 unique NFTs.</div>
    <a href="/#faqs">Go back</a>
</div>
      <NavbarSocial />
  </>
  );
};

export default SigninPage;
