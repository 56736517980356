
import React, { useState } from "react";

import Navbar from "../components/Navbar";

import Sidebar from "../components/Sidebar";

import NavbarSocial from "../components/NavbarSocial";
const SigninPage = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
  <>
   <div className="Container-1">
   <div className="faqs" >
  Does the first Transcendence collection have any use?
Our first collection of NFTs “Through the gods” is an entrance to the Transcendence community where you will have exclusive access to giveaways, exclusive draws for holders in collaboration with brands, and NFT masterclass projects together with our artists and founders. You can also be part of future projects and join us in our expansion to the metaverse.</div>
  <a href="/#collection">Go back</a>
     </div>
     <NavbarSocial />
  </>
  );
};

export default SigninPage;
