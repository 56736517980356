import styled from 'styled-components';

export const ServicesContainer = styled.div`
  margin-bottom:0px;
  height: auto;
  width: 100%
  display: flex;
  align-content: center;
  margin-left: 5em;
  
  @media screen and (max-width: 480px) {
    display: none;
  }

  @media screen and (max-width: 768px) {

  }
  @media screen and (max-width: 375px) {
    display: none;
  }
`;
