import React, { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import Navbar from "../components/Navbar";
import App from "../App.css";
import Sidebar from "../components/Sidebar";
import NavbarSocial from "../components/NavbarSocial";

const Areyouanartist = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
      } = useForm();
  
const onSubmit = (data) => {
    const buttonMailto = document.querySelector('#enviar');
    buttonMailto.setAttribute('href', 'mailto:platform@transcendencenft.com?subject=hi i am '+data.name+' and i need more information about the work '+data.email+'&body=' + data.message); 
    buttonMailto.click();
  }

  return (
    <>
    <div className="Container-1">
      <div className="formulario">
     
          <h2>Forms Artist</h2>
          <form onSubmit={handleSubmit(onSubmit)}>
            <label>Name</label> <br/>
           
            <input {...register('name')} type="text" required></input> <br/>
            <label>email</label> <br/>
            <input  {...register('email')} type="email" required></input> <br/>
            <label>message</label> <br/>
            <textarea  {...register('message')}  rows="10" cols="50" required></textarea> <br/>
            <input id="Beedhelp" type="submit" />
          </form>
    
     <a id="Goback" href="/">Go back</a>
      </div>
      </div>
      <NavbarSocial />
      <a href="mailto:platform@transcendencenft.com" id="enviar">mail</a>
    </>
  );
};

export default Areyouanartist;
