import styled from 'styled-components';

export const NavbarSocial1 = styled.div`
  position: fixed;
  max-width: 30px;
  height: 30px;
  top: 35%;
  left: 1rem;
  z-index: 20;
  @media screen and (max-width: 375px) {
    position: fixed;
    top: 100px;
    left: 1rem;
  }
  @media screen and (max-width: 480px) {
    display: inline;
  }
  
  
`;
export const NavItem = styled.li`
  max-width: 30px;
  list-style:none; 
`;

export const MenuIcon = styled.img`
width: 25px;
padding-bottom: 0.5em;
height: auto;
  @media screen and (max-width: 768px) {
  }
`;
