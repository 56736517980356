import React from "react";
import { MenuIcon, NavbarSocial1, NavItem  } from "./NavbarElements";
import discord from "../../images/redes/DISCORD.png";
import instagram from "../../images/redes/INSTAGRAM.png";
import twitter from "../../images/redes/TWITTER.png";
import telegram from "../../images/redes/TELEGRAM.png";

const NavbarSocial = () => {
  return (
    <>
      <NavbarSocial1>
        <div>
          <ul>
            <NavItem >
             
              <a
                href="https://www.instagram.com/transcendenceplatform/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <MenuIcon src={instagram} />
              </a>
            </NavItem >
            <NavItem >
              <a
                href="https://twitter.com/nftranscendence"
                target="_blank"
                rel="noopener noreferrer"
              >
                <MenuIcon src={twitter} />
              </a>
            </NavItem >
            <NavItem >
              <a
                href="https://discord.gg/QpaZbcJ4NQ"
                target="_blank"
                rel="noopener noreferrer"
              >
                <MenuIcon src={discord} />
              </a>
            </NavItem >
            <NavItem >
              <a href="https://t.me/TranscendencePlatform" target="_blank" rel="noopener noreferrer">
                <MenuIcon src={telegram} />
              </a>
            </NavItem >
          </ul>
        </div>
      </NavbarSocial1>
    </>
  );
};

export default NavbarSocial;
