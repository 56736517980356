import React from "react";
import vans from '../../images/marcas/VANS_1.png';
import eth from '../../images/marcas/ETHEREUM.png';
import nike from '../../images/marcas/NIKE_2.png';
import tmoua from '../../images/marcas/TMOUA.png';
import polygonP from '../../images/marcas/POLYGON.png';
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesWrapperDos,
  ServicesCard,
  ServicesMarca,
  ServicesContainerMobile,
} from "./ServicesElements";



const Partners = () => {
  return (
  <>
    <ServicesContainer id="partners">
      <ServicesH1>GOOD PARTNERS</ServicesH1>
      <ServicesWrapper>
        <ServicesCard>
          <ServicesMarca  src={vans} />
        </ServicesCard>
        <ServicesCard>
          <ServicesMarca src={eth} />
        </ServicesCard>
        <ServicesCard>
          <ServicesMarca src={nike}/>
        </ServicesCard>
        </ServicesWrapper>
        <ServicesWrapperDos>
        <ServicesCard>
          <ServicesMarca src={tmoua} />
        </ServicesCard>
        <ServicesCard>
          <ServicesMarca src={polygonP} />
        </ServicesCard>
        </ServicesWrapperDos>
    </ServicesContainer>
    <ServicesContainerMobile id="partners">
    <ServicesH1>GOOD PARTNERS</ServicesH1>
    <ServicesWrapper>
      <ServicesCard>
        <ServicesMarca  src={vans} />
      </ServicesCard>
      <ServicesCard>
        <ServicesMarca src={eth} />
      </ServicesCard>
      <ServicesCard>
        <ServicesMarca src={nike}/>
      </ServicesCard>
      <ServicesCard>
        <ServicesMarca src={tmoua} />
      </ServicesCard>
      <ServicesCard>
        <ServicesMarca src={polygonP} />
      </ServicesCard>
      </ServicesWrapper>
  </ServicesContainerMobile>
  </>
);
};

export default Partners;
