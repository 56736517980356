import styled from 'styled-components';
import { Link } from 'react-scroll';
import isMobile from "ismobilejs";

const mobile = isMobile().any ? "display: none;" : "display: flex;"

export const Alinear = styled.div`
display: flex;
flex: 1;
align-items: flex-start;
`;

export const HideOnMobile = styled.div`
${mobile}
`

export const ServicesContainer = styled.div`

  clear: both;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 13em;

  @media screen and (max-width: 768px) {
    margin-top: 3em;
    padding-left: 0em;
    align-items: center;
  }

  @media screen and (max-width: 480px) {
    margin-top: 15em;
    margin-left: 3em;
    margin-right: 1em;
  }

  @media screen and (max-width: 375px) {
    margin-top: 12em;
  }
`;
export const ServicesRoadmap= styled.div`
  max-width: 600px;
  align-items: center;
  text-align: center;
  position: relative;

  @media screen and (max-width: 1000px) {
   
  }

  @media screen and (max-width: 768px) {
   
  
  }
  @media screen and (max-width: 375px) {
    max-width: 350px;
    padding-left: 1.5rem;
  }
`;



export const ServicesIcon = styled.img`
  height: 200px;
  width: 160px;
  border-radius: 10px;
`;

export const ServicesH1 = styled.h1`
margin-top: 57rem!important;
font-family: 'Teko', sans-serif;
font-size: 3rem;
text-align: left;
font-weight: 900;
color:rgb(22, 238, 189);
  @media screen and (max-width: 375px) {
    font-size: 2rem;
    margin-top: 37rem!important;
  }
`;

export const ServicesWH1 = styled.h2`
color: rgb(146, 0, 234);
  font-size: 1.5rem;
  margin-top: 20px;
  text-align: left;
`;

export const ServicesP = styled.p`
  font-size: 1rem;
  text-align: left;
  color:#fff;
  font-family: 'Roboto', sans-serif;
  margin-top: 2rem;
  @media screen and (max-width: 375px) {
    margin-top: 0rem;
    font-size: 0.8rem
  }
`;
export const Lluvia = styled.img`
  hight:auto;
  width:100%; 
z-index: 20;
@media screen and (max-width: 375px) {
  display: none;
}
`;
export const Estrella1 = styled.img`
position: absolute;
top:73rem;
left:-7rem;
  height: 17rem;
  width: auto;
 
  transform:scale(2.0); -webkit-transform:scale(2.0);
  @media screen and (max-width: 375px) {
    display: none;
  }
`;
export const Estrella2 = styled.img`
position: absolute;
top:83rem;
left:-7rem;
  height: 17rem;
  width: auto;
 
  transform:scale(2.0); -webkit-transform:scale(2.0);
  @media screen and (max-width: 375px) {
    display: none;
  }
`;
export const Estrella3 = styled.img`
position: absolute;
top:90rem;
left:-7rem;
  height: 17rem;
  width: auto;
 
  transform:scale(2.0); -webkit-transform:scale(2.0);
  @media screen and (max-width: 375px) {
    display: none;
  }
`;
export const Estrella4 = styled.img`
position: absolute;
top:97rem;
left:-7rem;
  height: 17rem;
  width: auto;
 
  transform:scale(2.0); -webkit-transform:scale(2.0);
  @media screen and (max-width: 375px) {
    display: none;
  } 
`;
export const Estrella5 = styled.img`
position: absolute;
top:103rem;
left:-7rem;
  height: 17rem;
  width: auto;
 
  transform:scale(2.0); -webkit-transform:scale(2.0);
  @media screen and (max-width: 375px) {
    display: none;
  }
`;
export const Estrella6 = styled.img`
position: absolute;
top:113rem;
left:-7rem;
  height: 17rem;
  width: auto;
 
  transform:scale(2.0); -webkit-transform:scale(2.0);
  @media screen and (max-width: 375px) {
    display: none;
  }
`;
export const Estrella7 = styled.img`
position: absolute;
top:121rem;
left:-7rem;
  height: 17rem;
  width: auto;
 
  transform:scale(2.0); -webkit-transform:scale(2.0);
  @media screen and (max-width: 375px) {
    display: none;
  }
`;
export const IconEspacial = styled.img`
position: absolute;
top:68rem;
 left:39rem;
  height:auto;
  width: 160px;
  &:hover {
    transform: scale(1.20);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
  @media screen and (max-width: 375px) {
    display: none;
  }
`;
export const IconMundo = styled.img`
position: absolute;
top:75rem;
 left:39rem;
  height:auto;
  width: 160px;
  &:hover {
    transform: scale(1.20);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
  @media screen and (max-width: 375px) {
    display: none;
  }
`;
export const IconConfiguracion = styled.img`
position: absolute;
top:84rem;
 left:39rem;
  height:auto;
  width: 172px;
  &:hover {
    transform: scale(1.20);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
  @media screen and (max-width: 375px) {
    display: none;
  }
`;
export const IconInfinito = styled.img`
position: absolute;
top:90rem;
 left:39rem;
  height:auto;
  width: 172px;
  &:hover {
    transform: scale(1.20);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
  @media screen and (max-width: 375px) {
    display: none;
  }
`;
export const IconEthe = styled.img`
position: absolute;
top:95rem;
 left:41.2rem;
  height:auto;
  width: 92px;
  &:hover {
    transform: scale(1.20);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
  @media screen and (max-width: 375px) {
    display: none;
  }
`;
export const IconMente = styled.img`
position: absolute;
top:103rem;
 left:39rem;
  height:auto;
  width: 172px;
  &:hover {
    transform: scale(1.20);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
  @media screen and (max-width: 375px) {
    display: none;
  }
`;
export const IconSonido = styled.img`
position: absolute;
top:117rem;
 left:37rem;
  height:auto;
  width: 247px;
  &:hover {
    transform: scale(1.20);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
  
  @media screen and (max-width: 375px) {
    display: none;
  }
`;

