import React, { useState } from "react";
import CoverSection from "../components/CoverSection";
import Team from "../components/Team";
import Navbar from "../components/Navbar";
import Collection from "../components/Collection";
import Sidebar from "../components/Sidebar";
import Roadmap from "../components/Roadmap";
import RoadmapDos from "../components/RoadmapDos";
import Welcome from "../components/Welcome";
import Artis from "../components/Artist";
import Partners from "../components/Partners";
import Faqs from "../components/Faqs";
import NavbarSocial from "../components/NavbarSocial";
import RoadMapGrafico from "../components/RoadMapGrafico";
const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      {/* <CoverSection /> */}
      <Welcome />
      <RoadMapGrafico />
      <Collection />
      <Artis />
      <Roadmap />
      <RoadmapDos />
      <Team />
      <Partners />
      <Faqs />
      <NavbarSocial />
    </>
  );
};

export default Home;
