import React from "react";
import sonido from "../../images/graficos/SONIDO.png";
import mundo from "../../images/graficos/MUNDO.png";
import mente from "../../images/graficos/MENTE.png";
import lluvia from "../../images/graficos/LLUVIA.png";
import infinito from "../../images/graficos/INFINITO.png";
import estrella from "../../images/LINEAPUNTO.svg";
import espacial from "../../images/graficos/ESPACIAL.png";
import ethereum from "../../images/graficos/ETHERUM.png";
import configuracion from "../../images/graficos/CONFIGURACION.png";


import {
  IconMente,
  IconEthe, 
  IconInfinito,
  IconConfiguracion, 
  IconMundo,
  IconEspacial,
  IconSonido,
  Estrella7,
  Estrella6,
  Estrella5,
  Estrella4,
  Estrella3,
  Estrella2,
  Estrella1,
  Lluvia,
  ServicesWH1,
  ServicesContainer,
  ServicesRoadmap,
  ServicesH1,
  ServicesP,
  Alinear,
  HideOnMobile,
} from "./ServicesElements";

const RoadmapDos = () => {
  return (
    <>

      <ServicesContainer>
      <Alinear>
        <ServicesRoadmap>
          <ServicesH1>
            AFTER THE FIRST STEP,
            <br />
            WHAT DO WE HAVE UP OUR SLEEVES?
          </ServicesH1>
          <br />
          <HideOnMobile>
          <Estrella1 src={estrella}></Estrella1>
          <IconEspacial src={espacial}/>
          </HideOnMobile>
          <ServicesP>
            If you got here it is because you are interested in the
            <br />
            project and we love that.We have been dreaming,
            <br /> working and taking action for years to today do what we love
            with
            <br />
            the art that flows through our veins.
          </ServicesP>
          <br />
          <HideOnMobile>
          <Estrella2 src={estrella}></Estrella2>
          <IconMundo src={mundo} />
          </HideOnMobile>
          <ServicesP>
            We firmly believe that the community comes first, we are going to
            mention some of the next steps that we are going to take once the
            first transcendence collection is completely sold and we find
            ourselves in the middle of the metaverse having a drink.
          </ServicesP>
          <br />
          <HideOnMobile>
          <Estrella3 src={estrella}></Estrella3>
          <IconConfiguracion src={configuracion} />
          </HideOnMobile>
          <ServicesP>
            The transcedents are eligible to take part in giveaways, quizzes and
            all kinds of evolving initiatives that reward members of the
            Transcendence platform. For example, we already think to raffle
            shoes, jackets and clothes made by Transcendence artists, get free
            NFTs, crypto and many others things.
          </ServicesP>
          <br />
          <HideOnMobile>
          <Estrella4 src={estrella}></Estrella4>
          <IconInfinito src={infinito} />
          </HideOnMobile>
          <ServicesP>
            We aim to expand the universe through the Transcendence platform
            with infinite collections of underground artists who create value
            with us.
          </ServicesP>
          <br />
          <HideOnMobile>
          <Estrella5 src={estrella}></Estrella5>
          <IconEthe src={ethereum} />
          </HideOnMobile>
          <ServicesP>
            We have plans to create a Community Wallet with the intention of
            redistributing 10% of the Royalty Fee income back into the
            Transcendence Community Wallet. The community will use the demand
            for secondary transactions to its advantage and NFT holders can vote
            on a variety of alternatives for what they wish to do with their
            community funds
          </ServicesP>
          <br />
          <HideOnMobile>
          <Estrella6 src={estrella}></Estrella6>
          <IconMente src={mente} />
          </HideOnMobile>
          <ServicesP>
            The parties are always on our side. That is why, in order to
            contribute to all our holders and celebrate the progress of our
            accomplishments, we will be encourage the execution of exclusive
            events with the aim of exchanging information and growing a network
            with great future potential. Where? The community decides.
          </ServicesP>
          <br />
          <HideOnMobile>
          <Estrella7 src={estrella}></Estrella7>
          <IconSonido src={sonido} />
          </HideOnMobile>
          <ServicesP>
            We have many surprises coming soon in our journey. This is the
            beginning of the Transcendence Platform. Never forget that Our goal
            is to fly high, we like to rise above all.
          </ServicesP>
          <ServicesWH1>To be continued...</ServicesWH1>
          <br />
        </ServicesRoadmap>
        </Alinear>
      </ServicesContainer>
      <Lluvia src={lluvia} />

    </>
  );
};

export default RoadmapDos;
