import styled from 'styled-components';
export const ServicesContainer = styled.div`
 
  overflow: hidden!important;
  
  @media screen and (max-width: 375px) {
    display: none;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
  @media screen and (max-width: 480px) {
    display: none;
  }
  
`;

export const ServicesIcon = styled.img`
  height: 297px;
  width: 297px;
  margin:2px;
  border-radius: 10px;
  border: 3px solid rgb(22, 238, 189);
`;
export const ServicesIcon2 = styled.img`
  height: 20px;
  width: 20px;
  
`;

export const ServicesH1 = styled.h1`
margin-top:5%;
font-family: 'Teko', sans-serif;
font-size: 5rem;
font-weight: 900;
color:rgb(22, 238, 189);
  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
`;

export const ServicesH2 = styled.h2`
  font-size: 1rem;
  margin-bottom: 10px;
`;

export const ServicesP = styled.p`
  font-size: 1rem;
  text-align: center;
`;
export const ContenedorSlideshow = styled.div`
clear:both;
  width: 300px;
  display: flex;
  border-radius: 10px;
  flex-wrap: nowrap!important;
  position: relative;
  @media screen and (max-width: 375px) {
    
  }
`;
export const Slide = styled.div`
    width: 300px;
    z-index:1 !important;
   

`;
export const Controles =styled.div`
position:absolute;
top:0px;
z-index:2 !important;
width:195px;
height:100%;
pointer-events:none;
margin-left: -19px;
`;

export const Boton =styled.button`
position:absolute !important;
curson:pointer !important;
pointer-events:all !important;
outline:none !important;
background:none;
border:none;


width:50px;
height:100%;

transition: 0.3s ease all !important;
${props => props.derecho ? 'right:0 !important; ': 'left:0 !important;'}

`;



