import React from "react";
import './collection.css';
import Nft1 from "../../images/nft/nft-1.gif";
import Nft2 from "../../images/nft/nft-2.gif";
import Nft3 from "../../images/nft/nft-3.gif";
import Nft4 from "../../images/nft/NFT_4.jpg";
import Nft5 from "../../images/nft/nft_5.jpg";
import Nft6 from "../../images/nft/NFT_6.jpg";
import Nft7 from "../../images/nft/NFT_7.jpg";
import Nft8 from "../../images/nft/NFT_8.jpg";
import Nft9 from "../../images/nft/NFT_9.jpg";
import Nft10 from "../../images/nft/NFT_10.jpg";
import Nft11 from "../../images/nft/NFT_11.jpg";
import Nft12 from "../../images/nft/NFT_12.jpg";
import Nft13 from "../../images/nft/NFT_13.jpg";
import Nft14 from "../../images/nft/NFT_14.jpg";
import Nft15 from "../../images/nft/NFT_15.jpg";
import Nft16 from "../../images/nft/NFT_16.jpg";
import Nft17 from "../../images/nft/NFT_17.jpg";
import Nft18 from "../../images/nft/NFT_18.jpg";
import Nft19 from "../../images/nft/NFT_19.jpg";
import Nft20 from "../../images/nft/NFT_20.jpg";
import Nft21 from "../../images/nft/NFT_21.jpg";
import Nft22 from "../../images/nft/NFT_22.jpg";
import Nft23 from "../../images/nft/NFT_23.jpg";
import Nft24 from "../../images/nft/NFT_24.jpg";
import Nft25 from "../../images/nft/NFT_25.jpg";
import Nft26 from "../../images/nft/NFT_26.jpg";
import Nft27 from "../../images/nft/NFT_27.jpg";
import Nft28 from "../../images/nft/NFT_28.jpg";
import Nft29 from "../../images/nft/NFT_29.jpg";
import Nft30 from "../../images/nft/NFT_30.jpg";
import Nft31 from "../../images/nft/NFT_31.jpg";
import Nft32 from "../../images/nft/NFT_32.jpg";
import Nft33 from "../../images/nft/NFT_33.jpg";



import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import {
  ServicesContainer,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesWH1,
  ServicesColleH2,
  ServicesColleH2B,
  
} from "./ServicesElements";

const Collection = () => {
  return (
    <>  
    <ServicesContainer id="collection">
      <ServicesColleH2B>OUR FIRST NFT</ServicesColleH2B>
      <ServicesWH1>COLLECTION</ServicesWH1>
      <ServicesColleH2>by PABLO BENLLIURE</ServicesColleH2>

      <AliceCarousel autoPlay autoPlayInterval="3000">
      <ServicesWrapper>
        <ServicesCard>
          <ServicesIcon src={Nft1} />
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Nft2} />
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Nft3} />
        </ServicesCard>
        </ServicesWrapper>
        <ServicesWrapper>
        <ServicesCard>
          <ServicesIcon src={Nft4} />
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Nft5} />
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Nft6} />
        </ServicesCard>
        </ServicesWrapper>
        <ServicesWrapper>
        <ServicesCard>
          <ServicesIcon src={Nft7} />
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Nft8} />
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Nft9} />
        </ServicesCard>
        </ServicesWrapper>
        <ServicesWrapper>
        <ServicesCard>
          <ServicesIcon src={Nft10} />
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Nft11} />
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Nft12} />
        </ServicesCard>
        </ServicesWrapper>
        <ServicesWrapper>
        <ServicesCard>
          <ServicesIcon src={Nft13} />
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Nft14} />
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Nft15} />
        </ServicesCard>
        </ServicesWrapper>
        <ServicesWrapper>
        <ServicesCard>
          <ServicesIcon src={Nft16} />
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Nft17} />
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Nft18} />
        </ServicesCard>
        </ServicesWrapper>
        <ServicesWrapper>
        <ServicesCard>
          <ServicesIcon src={Nft19} />
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Nft20} />
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Nft21} />
        </ServicesCard>
        </ServicesWrapper>
        <ServicesWrapper>
        <ServicesCard>
          <ServicesIcon src={Nft22} />
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Nft23} />
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Nft24} />
        </ServicesCard>
        </ServicesWrapper>
        <ServicesWrapper>
        <ServicesCard>
          <ServicesIcon src={Nft25} />
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Nft26} />
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Nft27} />
        </ServicesCard>
        </ServicesWrapper>
        <ServicesWrapper>
        <ServicesCard>
          <ServicesIcon src={Nft28} />
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Nft29} />
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Nft30} />
        </ServicesCard>
        </ServicesWrapper>
        <ServicesWrapper>
        <ServicesCard>
          <ServicesIcon src={Nft31} />
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Nft32} />
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Nft33} />
        </ServicesCard>
        </ServicesWrapper>
        </AliceCarousel>

      <div>
        <a className="A-boton" href="/Mint">MINT YOUR NFT</a> <a className="A-boton" href="/Need-help" >NEED HELP?</a>
      </div> <br/>
      <div className="Espacio"></div>
      <div>
        <a className="A-boton" href="/Are-you-an-Artist" >ARE YOU AN ARTIST? SEND US REQUEST</a>
      </div><br/>
     <div className="artist-0"> <div className="artist-a">COOMING SOON</div><div className="artist-b">more artists!</div></div>
      <div>
        <a className="A-boton" href="/#launch">DO YOU WANT TO TRASCENDENCE?</a>
      </div><br/>

    </ServicesContainer>
    </>
  );
};

export default Collection;
