import React from "react";
import { useState } from "react";
import LogoWelcome from "../../images/Logotipo_Principal_Blanco.png";
import {
  ArrowForward,
  ArrowRight,
} from "./CoverElements";
import "./style.css"

import {
  WelcomeP,
  WelcomeDivLogo,
  WelcomeLogo,
  WelcomeDivButt,
  ServicesContainer,
  WelcomeContainerDos,
  ServicesWH1,
  WelcomeContainerDosB,
  ServicesWH2,
  ServicesWP,
} from "./ServicesElements";
import { Button2 } from "../ButtonElements2";

const Welcome = () => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };
  return (
    <>
      <ServicesContainer id="welcome">
        <WelcomeDivLogo>
          <WelcomeLogo src={LogoWelcome} id="logo" />
          <WelcomeP>OUR GOAL IS TO FLY HIGH, WE LIKE TO RISE ABOVE ALL</WelcomeP>
         
        </WelcomeDivLogo>
        <WelcomeDivButt>
        <Button2
            to="welcome2"
            onMouseEnter={onHover}
            onMouseLeave={onHover}
            primary="true"
            dark="true"
          >
            SCROLL DOWN {hover ? <ArrowForward /> : <ArrowRight />}
          </Button2>
        </WelcomeDivButt>
      </ServicesContainer>
    <div id="welcome2"></div>
     <WelcomeContainerDos >
     <ServicesWH1>WELCOME</ServicesWH1>
     <ServicesWH2>OUR GOAL IS TO FLY HIGH, WE LIKE TO BE ABOVE.</ServicesWH2>
     <WelcomeContainerDosB >
        <ServicesWP>Each culture has its own way of connecting with the transcendence, we
choose to do it through art and technology.</ServicesWP>
<ServicesWP>Transcendence is an NFT art community. Diving into our journey means
joining a family of NFT investors, dreamers, and enthusiasts who believe
in the future of cryptocurrencies and blockchain technology.</ServicesWP>
<ServicesWP>This is a place for emerging and expanding artists who have our platform
to share and exhibit art across the planet without borders through a
digitized platform adapted to the web 3.0 ecosystem.</ServicesWP>
     </WelcomeContainerDosB>
     </WelcomeContainerDos>
    </>
  );
};

export default Welcome;
