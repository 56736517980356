import styled from 'styled-components';
import { Link } from 'react-scroll';
import isMobile from 'ismobilejs';

const hideOnMobile = isMobile().any ? "display: none" : "display: flex"

export const ServicesContainer = styled.div`
  height: 800px;
  position: relative;
  align-items: left;
  margin-left: -35%;
  @media screen and (max-width: 768px) {
    height: 1100px;
    align-items: center;
    margin-left: 0px;
  }

  @media screen and (max-width: 480px) {
    align-items: center;
    margin-left: 2em;
    margin-right: 1em;
  }
  @media screen and (max-width: 375px) {
    margin-left:3rem;
    max-width: 360px;
  }
`;

export const HideOnMobile = styled.div`
${hideOnMobile}
`;

export const ServicesRoadmap= styled.div`
  max-width: 600px;

  align-items: center;
  text-align: center;
 

  @media screen and (max-width: 1000px) {
   
  }

  @media screen and (max-width: 768px) {
   
  
  }
  @media screen and (max-width: 375px) {
    max-width: 350px;
  }
`;
export const ServicesWrapper = styled.div`
  max-width: 800px;
  align-items: left;
  text-align: left;
  position: relative;

  @media screen and (max-width: 768px) { 

  }
  @media screen and (max-width: 375px) {
    max-width: 340px;
  }
`;

export const ServicesCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  max-height: 340px;
  margin: 3.5rem;
  border: 1px solid rgb(247, 237, 54);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.12);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
  @media screen and (max-width: 375px) {
    max-height: 300px;
  }
`;
export const ServicesEstrella = styled.img`
position: absolute;
top:10rem;
left:-7rem;
  height: 17rem;
  width: auto;
 
  transform:scale(2.0); -webkit-transform:scale(2.0);
  @media screen and (max-width: 375px) {
    display: none;
  }
`;
export const ServicesEstrella2 = styled.img`
position: absolute;
top:24rem;
left:-7rem;
  height: 17rem;
  width: auto;
  
  transform:scale(2.0); -webkit-transform:scale(2.0);
  @media screen and (max-width: 375px) {
    display: none;
  }
`;
export const ServicesEstrella3 = styled.img`
position: absolute;
top:40rem;
left:-7rem;
  height: 17rem;
  width: auto;
 
  transform:scale(2.0); -webkit-transform:scale(2.0);
  @media screen and (max-width: 375px) {
    display: none;
  }

`;
export const ServicesIcon = styled.img`
  height: 200px;
  width: 160px;
  border-radius: 10px;
  @media screen and (max-width: 375px) {
    display: none;
  }
`;

export const ServicesH1 = styled.h1`
margin-top:5%;
font-family: 'Teko', sans-serif;
font-size: 5rem;
font-weight: 900;
color:rgb(22, 238, 189);
  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
  @media screen and (max-width: 375px) {
    
  }
`;

export const ServicesWH1 = styled.h2`
color: rgb(146, 0, 234);
  font-size: 1.5rem;
  margin-bottom: 10px;
  @media screen and (max-width: 375px) {
    
  }
`;

export const ServicesP = styled.p`
  font-size: 1rem;
  text-align: left;
  color:#fff;
  font-family: 'Roboto', sans-serif;
  margin-left: 2rem;
  @media screen and (max-width: 375px) {
    font-size: 0.8rem;
    margin-left: 1rem;
  }
`;
export const ButtonScroll = styled(Link)`
border: 1px solid #16EEBD;
color: #16EEBD !important;
border-radius: 9px;
width: 18rem;
font-size: 1.2rem;
margin-top: 2rem;
padding-left: 1.5rem;
padding-right: 1.5rem;
`;
export const Cohete = styled.img`
position: absolute;
top:4rem;
left:43.2rem;
  height: 40rem;
  width: auto;
  &:hover {
    transform: scale(1.10);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
  @media screen and (max-width: 375px) {
    display: none;
  }
 
`;
export const Base = styled.img`
position: absolute;
bottom:0;
left:42.5rem;
width: 10rem;
@media screen and (max-width: 375px) {
  display: none;
}
 
`;
export const Estrella = styled.img`
position: absolute;
top:24rem;
left:44rem;
height:9rem;
  width: auto;
  z-index: -1;
  transform:scale(2.0); -webkit-transform:scale(2.0);
  @media screen and (max-width: 375px) {
    display: none;
  }
`;
export const Man = styled.img`
position: absolute;
bottom: 5rem;
left:43.5rem;
  height: 9rem;
  width: auto;
  &:hover {
    transform: scale(1.50);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
 
  @media screen and (max-width: 375px) {
    display: none;
  }
`;
export const ServicesCoete= styled.div`
 clear:both;
 position:relative;
 text-align:center;
 @media screen and (max-width: 375px) {
  display: none;
}
`;
