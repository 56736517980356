import React from "react";
import "./style.css";

import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
} from "./ServicesElements";

const Faqs = () => {
  return (
    <>
      <ServicesContainer id="faqs">
        <ServicesWrapper>
          <ServicesH1>FAQS</ServicesH1>
        </ServicesWrapper>

        <ServicesWrapper>
          <details open>
            <summary>WHAT IS TRANSCENDENCE?</summary>
            <div className="faq__content">
              <p>
                It is a digital space adapted on web 3.0 for emerging and
                expanding artists where dreamers, enthusiasts, and investors
                believe in the future of cryptocurrencies and blockchain
                technology.
              </p>
            </div>
          </details>

          <details>
            <summary>
              DOES THE FIRST TRANSCENDENCE COLLECTION HAVE ANY USE?
            </summary>
            <div className="faq__content">
              <p>
                Our first collection of NFTs “Through the gods” is an entrance
                to the Transcendence community where you will have exclusive
                access to giveaways, exclusive draws for holders in
                collaboration with brands, and NFT masterclass projects together
                with our artists and founders. You can also be part of future
                projects and join us in our expansion to the metaverse.
              </p>
            </div>
          </details>

          <details>
            <summary>
              HOW MANY NFTS ARE THERE IN THE THROUGH-THE-GOODS COLLECTION?
            </summary>
            <div className="faq__content">
              <p>
                Transcendence will release Pablo Benlliure's first digital
                collection of 990 unique NFTs.
              </p>
            </div>
          </details>

          <details>
            <summary>WHY SHOULD I INVEST IN TRANSCENDENCE NFTS?</summary>
            <div className="faq__content">
              <p>
                Investing in the TRANSCENDENCE collection is investing in the
                future, so we not only create experiences but also value through
                a community that will connect you with great artists.
                Transcendence aims to take your investments to the virtual world
                where the opportunities are endless, through partnerships with
                great brands and the benefits that will be at your fingertips.
              </p>
            </div>
          </details>
        </ServicesWrapper>
      </ServicesContainer>
    </>
  );
};

export default Faqs;
