import React, { useState } from "react";
import Video from "../../videos/video.mp4";
import Video2 from "../../videos/360X740.mp4";
import {
  CoverContainer,
  CoverBg,
  VideoBg,
  CoverContent,
  CoverH1,
  CoverP,
  CoverBtnWrapper,
  ArrowForward,
  ArrowRight,
} from "./CoverElements";
import { Button } from "../ButtonElements";
import isMobile from "ismobilejs";

const video = isMobile().any ? Video2 : Video;

const CoverSection = () => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  return (
    <CoverContainer id="home">
      <CoverBg>
        <VideoBg autoPlay loop src={video} type="video/mp4" />
      </CoverBg>
      <CoverContent>
        <CoverH1></CoverH1>
        <CoverP></CoverP>
        <CoverBtnWrapper>
          <Button
            to="welcome"
            onMouseEnter={onHover}
            onMouseLeave={onHover}
            primary="true"
            dark="true"
          >
            GET STARTED {hover ? <ArrowForward /> : <ArrowRight />}
          </Button>
        </CoverBtnWrapper>
      </CoverContent>
    </CoverContainer>
  );
};

export default CoverSection;
