
import React, { useState } from "react";
import App from "../App.css";
import Navbar from "../components/Navbar";

import Sidebar from "../components/Sidebar";

import NavbarSocial from "../components/NavbarSocial";
const SigninPage = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
  <>
   <div className="Container-1">
 
   <div className="faqs" >Why should I invest in TRANSCENDENCE NFTs?
Investing in the TRANSCENDENCE collection is investing in the future, so we not only create experiences but also value through a community that will connect you with great artists. Transcendence aims to take your investments to the virtual world where the opportunities are endless, through partnerships with great brands and the benefits that will be at your fingertips.</div>
    <a href="/#faqs">Go back</a>
      </div>
      <NavbarSocial />
  </>
  );
};

export default SigninPage;
